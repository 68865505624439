* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', Helvetica, Arials, sans-serif
}